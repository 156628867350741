import React from 'react'

const ItemCard = ({item}) => {
  return (
    <div className=' relative overflow-x-auto shadow-lg w-full h-40  bg-primaryColor  gap-4 py-3 px-3 rounded-lg flex flex-row justify-start'>
       <div className=' w-[25%] max-md:w-[35%]  rounded-lg overflow-hidden  relative  aspect-[9/9]'>
        <img className=' w-full h-full' src={`https://api.softpro.me/CustomersAPIs/915562592/images/${item.pic}`}></img>

       </div>

       <div className=' w-[75%] max-md:w-[65%] flex flex-col justify-between py-4'>
       <h2 className=' text-xl font-mono'>{item.description}</h2>
       <p className=' font-mono font-bold text-lg'>${parseFloat(item.price1).toFixed(2)}</p>
       </div>
    </div>
  )
}

export default ItemCard