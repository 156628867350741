
import { FaLocationArrow, FaPhone, FaSearch, FaSpinner } from "react-icons/fa";
import image from './assets/images/logo.png'
import ItemCard from "./components/itemCard";
import axios, { all } from "axios";
import AOS from 'aos';
import 'aos/dist/aos.css';
import { useEffect, useState } from "react";
function App() {
  const [items, setItems] = useState(null);
  const [loading,setLoading] = useState(false);
  const [activeCategory,setActiveCategory] = useState(null);
  const [categories,setcategories] = useState(null);
  const [allItems ,setAllItems] = useState(null);



  useEffect(() => {
    AOS.init();
  }, [])
  function search(value){
    if(value == ''){
        setItems(allItems.filter((item)=>item.idSubC == activeCategory))
    }else{
   setItems(allItems.filter((item)=>item.description.toLowerCase().includes(value.toLowerCase())));
    }
  }

  useEffect(()=>{
  setLoading(true);
  axios.get("https://api.softpro.me/CustomersAPIs/915562592/index.php").then((respons)=>
  {
if(respons.data.success){
    setAllItems(respons.data.items)
  //  allItems = respons.data.items;
  //  console.log(allItems)
   setActiveCategory(respons.data.categories[0].idsubc)
   setcategories(respons.data.categories)
   setItems(respons.data.items.filter((item)=>item.idSubC == respons.data.categories[0].idsubc ))

}
setLoading(false);
  }
  )
  },[])


useEffect(()=>{
// console.log(allItems)
  if(allItems){
  setItems(allItems.filter((item)=>item.idSubC == activeCategory))
  }
},[activeCategory])


  return (
    loading ?
    <div className=" w-screen flex justify-center text-center h-screen">
      <FaSpinner className=" text-3xl my-auto animate-spin"/>
    </div>
    :

    <div className=" bg-background w-screen h-full flex justify-center ">

      <div className=" overflow-x-hidden  relative   flex flex-col justify-start  h-full w-[40%]  max-md:w-full ">

        <div className="   h-44 absolute w-full bg-black backImage"></div>
        <div className="   mt-32 z-50 h-full bg-white w-full rounded-t-[37px]">
          <div className=" px-2  w-full flex flex-row justify-between">
            <div className=" pt-5">
              <div className="   w-24 h-24 max-md:h-24 max-md:w-24   ">
                <img className=" w-full h-full" src={image}></img>
              </div>
            </div>
            <div className="  justify-end text-right flex flex-col gap-3">
              <h2  data-aos="fade-left"
    data-aos-anchor-placement="center-bottom"
    data-aos-duration="1000" className=" text-2xl  font-mono  text-nowrap whitespace-nowrap text-black">Manarah El-Menieh</h2>
              <p data-aos="fade-left"
              data-aos-delay="200"
    data-aos-anchor-placement="center-bottom"
    data-aos-duration="1000" className=" flex flex-row gap-2 text-right justify-end text-textMute text-xs">
                <span >Menieh Autostrad Danniyeh - 7ed Zayton </span> <FaLocationArrow className=" my-auto " /></p>
              <p data-aos="fade-left"
              data-aos-delay="300"
    data-aos-anchor-placement="center-bottom"
    data-aos-duration="1000" className="flex flex-row gap-2 text-right justify-end text-textMute text-xs"> <span> 71 85 23 27 / 81 325 434</span> 
              <FaPhone className=" my-auto " /></p>
            </div>
          </div>






          <div className="mx-2 max-w-full gap-3 flex flex-row overflow-x-auto relative mt-5">
            { categories && categories.map((item)=>{
              return(
                <button onClick={()=>{
                  setActiveCategory(item.idsubc)
                  
                }} className={ ` ${item.idsubc == activeCategory?" bg-primaryColor text-white ":"bg-white text-black"}  flex justify-center text-center  w-fit 
                 text-nowrap whitespace-nowrap  px-2 py-1 
                 border-4  font-mono  rounded-full border-primaryColor`}>
                <span>  {item.description} </span>
                </button>
              )
            })}
          

            
           
 
</div>







<div className=" relative px-5 mt-3 w-full h-12  ">
<input onChange={(e)=>search(e.target.value)}  placeholder=" Search ..." className="  placeholder:text-secondary rounded-lg py-2 pl-2 pr-5 w-full outline-0 bg-[#d6d6d6]"/>
<FaSearch className=" absolute  top-3  right-7"/>
</div>




<div className=" flex flex-col gap-3 px-2 mt-5">
  {items && items.map((item)=>{
    return (<div key={item.id} data-aos="fade-up "
    data-aos-anchor-placement="center-bottom"><ItemCard item={item}/></div>
    )
  }) }

</div>
        </div>















      </div>
    </div>
  );
}

export default App;
